import { INIT_IMPORTS } from '../products/ProductActions';
import {
  ADD_CLIENT,
  API_RESULT,
  CHANGE_FILTER,
  CLEAN_API_RESULT,
  CLEAR_IMPORT_EXPORT_CLIENTS_RESPONSE,
  CLIENTS_CHANGE,
  DELETE_CLIENT, DELETE_CLIENT_PRICE,
  EXPORT_CLIENTS_RESPONSE,
  IMPORT_CLIENTS_RESPONSE,
  PICK_CLIENT,
  UPDATE_CLIENT,
  UPSERT_CLIENT_PRICE
} from './ClientActions';

export default (state, action) => {
  switch (action.type) {
    case ADD_CLIENT:
      return {
        ...state,
        clients: [...state.clients, action.payload],
        apiResult: {
          action: 'add',
          status: 'success',
          message: 'Cliente creado correctamente'
        }
      }
    case CHANGE_FILTER:
      return {
        ...state,
        filters: action.payload
      }
    case CLIENTS_CHANGE:
      return {
        ...state,
        clients: [...action.payload.clients],
        totalClients: action.payload.totalClients
      }
    case PICK_CLIENT:
      return {
        ...state,
        clientPicked: action.payload.client,
        clientOrders: action.payload.clientOrders,
        clientPrices: action.payload.clientPrices
      }
    case EXPORT_CLIENTS_RESPONSE:
      return {
        ...state,
        clientsExported: action.payload
      }
    case IMPORT_CLIENTS_RESPONSE:
      return {
        ...state,
        clientsImported: action.payload,
        loading: false
      }
    case CLEAR_IMPORT_EXPORT_CLIENTS_RESPONSE:
      return {
        ...state,
        clientsImported: null,
        clientsExported: null
      }
    case CLEAN_API_RESULT:
      return {
        ...state,
        apiResult: null
      }
    case API_RESULT:
      return {
        ...state,
        apiResult: action.payload
      }
    case DELETE_CLIENT:
      return {
        ...state,
        clients: [...state.clients].filter(client => client.id !== action.payload),
        clientPicked: null,
        apiResult: {
          action: 'delete',
          status: 'success',
          message: 'Cliente eliminado correctamente'
        }
      }
    case DELETE_CLIENT_PRICE:
      return {
        ...state,
        clientPrices: [...state.clientPrices].filter(price => price.id !== action.payload),
        apiResult: {
          action: 'update',
          status: 'success',
          message: 'Precio de cliente eliminado correctamente'
        }
      }
    case UPSERT_CLIENT_PRICE:
      const updatedPrices = [...state.clientPrices];
      const priceIndex = updatedPrices.findIndex(price => price.id === action.payload.id);

      if (priceIndex < 0) {
        updatedPrices.push(action.payload);
      } else {
        updatedPrices[priceIndex] = action.payload
      }

      return {
        ...state,
        clientPrices: updatedPrices,
        apiResult: {
          action: priceIndex < 0 ? 'create' : 'update',
          status: 'success',
          message : `Precio ${priceIndex < 0 ? "creado" : "actualizado"} correctamente`
        }
      }
    case UPDATE_CLIENT:
      return {
        ...state,
        clients: state.clients.map(client => client.id === action.payload.id ? action.payload : client),
        apiResult: {
          action: 'update',
          status: 'success',
          message: 'Estado del cliente actualizado correctamente'
        },
        clientPicked: action.payload
      }
    case INIT_IMPORTS:
      return {
        ...state,
        loading: true
      }
    default:
      return state;
  }
}
