import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import NavBar from './NavBar';
import TopBar from './TopBar';
import FamilyState from '../../hooks/families/FamilyState';
import ProductState from '../../hooks/products/ProductState';
import TaxState from '../../hooks/taxes/TaxState';
import FeeState from '../../hooks/fees/FeeState';
import PriceState from '../../hooks/prices/PriceState';
import ClientState from '../../hooks/clients/ClientState';
import OrderState from '../../hooks/orders/OrderState';
import NotificationState from '../../hooks/notifications/NotificationState';
import ShippingMethodState from '../../hooks/shipping_methods/ShippingMethodState';
import ShippingAreaState from '../../hooks/shipping_areas/ShippingAreaState';
import PaymentMethodState from '../../hooks/payment_methods/PaymentMethodState';
import VenueState from '../../hooks/venues/VenueState';
import CouponState from '../../hooks/coupons/CouponState';
import HomeSectionState from '../../hooks/home_sections/HomeSectionState';
import CommercialState from '../../hooks/commercials/CommercialState';
import PartnerState from '../../hooks/partners/PartnerState';
import AnalyticsState from '../../hooks/analytics/AnalyticsState';
import VoucherState from '../../hooks/vouchers/VoucherState';
import VoucherProductState from '../../hooks/voucher_products/VoucherProductState';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%'
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 64,
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 256
    }
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto'
  }
}));

function DashboardLayout({ children }) {
  const classes = useStyles();
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);

  return (
    <TaxState>
      <FeeState>
        <ClientState>
          <OrderState>
            <PriceState>
              <FamilyState>
                <ProductState>
                  <NotificationState>
                    <ShippingMethodState>
                      <ShippingAreaState>
                        <PaymentMethodState>
                          <VenueState>
                            <CouponState>
                              <HomeSectionState>
                                <CommercialState>
                                  <PartnerState>
                                    <AnalyticsState>
                                      <VoucherState>
                                        <VoucherProductState>

                                          <div className={classes.root}>
                                            <TopBar onMobileNavOpen={() => setMobileNavOpen(true)}/>
                                            <NavBar
                                              onMobileClose={() => setMobileNavOpen(false)}
                                              openMobile={isMobileNavOpen}
                                            />
                                            <div className={classes.wrapper}>
                                              <div className={classes.contentContainer}>
                                                <div className={classes.content}>
                                                  {children}
                                                </div>
                                              </div>
                                            </div>
                                          </div>

                                        </VoucherProductState>
                                      </VoucherState>
                                    </AnalyticsState>
                                  </PartnerState>
                                </CommercialState>
                              </HomeSectionState>
                            </CouponState>
                          </VenueState>
                        </PaymentMethodState>
                      </ShippingAreaState>
                    </ShippingMethodState>
                  </NotificationState>
                </ProductState>
              </FamilyState>
            </PriceState>
          </OrderState>
        </ClientState>
      </FeeState>
    </TaxState>
  );
}

DashboardLayout.propTypes = {
  children: PropTypes.any
};

export default DashboardLayout;
